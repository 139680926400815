.list {
  text-align: left;
  max-width: 750px;
  margin: auto;
}

.submit-form {
  max-width: 300px;
  margin: auto;
}

.edit-form {
  max-width: 300px;
  margin: auto;
}
.container {
  max-width: 2000px;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
/*Estilo do Componente Data picker em Tab Geral */
.input__Data{
  width: 255px;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #c4c4c4;
  padding: 5px;
  color: black;

  
}

.input__Data:hover {
  transition: all 0.2s ease-in;
  border: 1px solid rgb(26, 25, 25);

}

/*Solução para as setas */

input[type=number]::-webkit-inner-spin-button { 
  -webkit-appearance: none;
  
}
input[type=number] { 
 -moz-appearance: textfield;
 appearance: textfield;

}

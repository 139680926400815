.aplicarDescontoseAcrecimos {
  border-radius: 5px;
  background-color: #009180;
  border: 1px solid rgba(76, 77, 76, 0.1550595238095238);
  color: white;
  height: 2.4rem;
}
.adicionarDescontoEspecial {
  background-color: #009180;
  border: 1px solid rgba(76, 77, 76, 0.1550595238095238);
  color: white;
  padding: 0px 0.68rem;
  height: 2.4rem;
  border-radius: 5px;
}
.adicionarDescontoEspecial:hover {
  background-color: #006e62;
  border: 1px solid rgba(76, 77, 76, 0.1550595238095238);
  color: #ffffff;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.row .ButtonStyle {
  border-radius: 4px;
  background-color: #009180;
  border: 1px solid rgba(76, 77, 76, 0.1550595238095238);
  color: white;
  height: 2.4rem;
}
.row .ButtonStyle:hover {
  background-color: #006e62;
  border: 1px solid rgba(76, 77, 76, 0.1550595238095238);
  color: #ffffff;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.row .ButtonStyle:active {
  background: #009180;
  border: 1px solid rgba(76, 77, 76, 0.1550595238095238);
  color: white;
}
.BtnBuscar {
  background-color: #009180;
  border: 1px solid rgba(76, 77, 76, 0.1550595238095238);
  border-radius: 5px;
  color: white;
  height: 2.3rem;
  padding: 4px 0.7rem;
}
.confirmar {
  background-color: #009180;
  border-radius: 5px;
  border: 1px solid rgba(76, 77, 76, 0.1550595238095238);
  color: white;
  height: 2.3rem;
}
.confirmar:hover {
  background-color: #006e62;
  border: 1px solid rgba(76, 77, 76, 0.1550595238095238);
  color: #ffffff;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.DescontoEspecialOk {
  border-radius: 5px;
  background-color: #009180;
  border: 1px solid rgba(76, 77, 76, 0.1550595238095238);
  color: white;
  padding: 0px 0.7rem;
  height: 2.3rem;
}
.DescontoEspecialOk:hover {
  background-color: #006e62;
  transition: all 0.3s ease-in-out;
}
.Lupabuscar {
  background-color: #009180;
  border: 1px solid rgba(76, 77, 76, 0.1550595238095238);
  border-radius: 5px;
  color: white;
  padding: 0px 0.68rem;
  height: 2.31rem;
}
.Lupabuscar:hover {
  transition: all 0.3s ease-in-out;
  background-color: #006e62;
}

.LupabuscarComponenteProduto {
  display: "flex";
  justify-content: "center";
  align-items: "center";
  width: "42px";
  background-color: #009180;
  border: 1px solid rgba(76, 77, 76, 0.1550595238095238);
  border-radius: 5px;
  color: white;
  padding: 0px 0.45rem;
  height: "40px";

}
.LupabuscarComponenteProduto:hover {
  transition: all 0.3s ease-in-out;
  background-color: #006e62;
}


.selecionarComponenteCompleto {
 
    background: "#008975";
    width: "100%";
    height: "35px";
    border: "none";
    color: "white";

}
.selecionarComponenteCompleto:hover {
  transition: all 0.3s ease-in-out;
  background-color: #006e62;
}
.NovoOrcamento{
  background-color: #009180;
  border: 1px solid rgba(76, 77, 76, 0.1550595238095238);
  border-radius: 5px;
  color: white;
  height: 2.3rem;
  padding: 4px 0.7rem;
  text-decoration: none;

}
.NovoOrcamento:hover {
  transition: all 0.3s ease-in-out;
  background-color: #006e62;
  color: aliceblue;
}
.salvarvencimento {
  background-color: #009180;
  border-radius: 5px;
  border: 1px solid rgba(76, 77, 76, 0.1550595238095238);
  color: white;
  height: 2.3rem;
}
.salvarvencimento:hover {
  transition: all 0.3s ease-in-out;
  background-color: #006e62;
}
.selecionar {
  background-color: #009180;
  border-radius: 5px;
  border: 1px solid rgba(76, 77, 76, 0.1550595238095238);
  color: white;
  height: 2.3rem;
}


.aplicarDescontoseAcrecimos:disabled,
.adicionarDescontoEspecial:disabled,
.row .ButtonStyle:disabled,
.BtnBuscar:disabled,
.confirmar:disabled,
.DescontoEspecialOk:disabled,
.Lupabuscar:disabled,
.LupabuscarComponenteProduto:disabled,
.selecionarComponenteCompleto:disabled,
.NovoOrcamento:disabled,
.salvarvencimento:disabled,
.selecionar:disabled {
  background-color: #ccc; /* Cinza desativado */
  border-color: #ccc; /* Bordas também */
  color: #666; /* Cor do texto */
}